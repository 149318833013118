@use "../abstracts";
@use "sass:map";

/**
 * Basic typography style for copy text
 */
body {
  color: map.get(abstracts.$colors, "text");
}

h1,
h2 {
  font-family: abstracts.$heading-font-stack;
  font-optical-sizing: auto;
  font-style: normal;
  font-kerning: normal;
  text-align: center;
  text-shadow: 0px 0.07rem 0.1rem rgba(0, 0, 0, 0.2);

  &::before,
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  &::before {
    content: "[ ";
    margin-right: 0.5rem;
  }

  &::after {
    content: " ]";
    margin-left: 0.5rem;
  }
}

h1 {
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-wrap: nowrap;
  font-weight: 900;
  font-size: 1.6rem;
  height: 100%;

  span {
    font-size: 1rem;
    font-weight: 450;
    display: inline-flex;
    align-items: last baseline;
    justify-content: center;
    height: auto;
    margin: 0px;
    padding: 0px;
  }

  @include abstracts.respond-to("xsmall") {
    font-size: 1.8rem;
    span {
      font-size: 1.2rem;
    }
  }
  @include abstracts.respond-to("small") {
    font-size: 2.1rem;
    span {
      font-size: 1.5rem;
    }
  }
  @include abstracts.respond-to("medium") {
    font-size: 2.4rem;
    span {
      font-size: 1.8rem;
    }
  }
  @include abstracts.respond-to("large") {
    font-size: 2.6rem;
    span {
      font-size: 2rem;
    }
  }
  @include abstracts.respond-to("xlarge") {
    font-size: 2.8rem;
    span {
      font-size: 2.2rem;
    }
  }
}

h2 {
  text-wrap: nowrap;
  font-weight: 900;
  font-size: 0.8rem;

  span {
    font-weight: 350;
    font-size: 0.5;
  }

  @include abstracts.respond-to("xsmall") {
    font-size: 0.9rem;
    span {
      font-size: 0.6rem;
    }
  }
  @include abstracts.respond-to("small") {
    font-size: 1.2rem;
    span {
      font-size: 0.9rem;
    }
  }
  @include abstracts.respond-to("medium") {
    font-size: 1.4rem;
    span {
      font-size: 1.1rem;
    }
  }
  @include abstracts.respond-to("large") {
    font-size: 1.7rem;
    span {
      font-size: 1.4rem;
    }
  }
  // @include abstracts.respond-to("xlarge") {
  //   font-size: 3.5rem;
  // }
}

h3 {
  font-family: abstracts.$text-font-stack;
  text-align: center;
  text-shadow: 0px 0.07rem 0.1rem rgba(map.get(abstracts.$colors, "text"), 0.1);

  text-wrap: nowrap;
  font-weight: 600;
  font-size: 0.6rem;

  @include abstracts.respond-to("xsmall") {
    font-size: 0.7rem;
  }
  @include abstracts.respond-to("small") {
    font-size: 0.8rem;
  }
  @include abstracts.respond-to("medium") {
    font-size: 0.9rem;
  }
  @include abstracts.respond-to("large") {
    font-size: 1rem;
  }
  // @include abstracts.respond-to("xlarge") {
  //   font-size: 3.5rem;
  // }
}
