@use "../abstracts";
@use "sass:map";

// slide container
div.slide__container {
  // overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  color: map.get(abstracts.$colors, "text");
  padding: 10vh 0;
  z-index: 2;
}

// slide id backgrounds
@each $name, $img in abstracts.$backgrounds {
  #s--#{$name} {
    background-image: abstracts.image($img);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    opacity: 0.9;
  }
}

// override for about bg
#s--about {
  background-position: center top;
}

// row backgrounds
#sr--1 div.slide {
  background-color: map.get(abstracts.$colors, "primary");
}

#sr--2 div.slide {
  background-color: map.get(abstracts.$colors, "secondary");
}

#sr--3 div.slide {
  background-color: map.get(abstracts.$colors, "accent");
}

#sr--4 div.slide {
  background-color: map.get(abstracts.$colors, "text");
}

//actions
.slide__action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: map.get(abstracts.$colors, "text");
  padding: 0.3rem;
  border-radius: 0.5rem;
  z-index: 4;
  width: auto;
  height: auto;
  min-height: 36px;
  min-width: 36px;
  border: none;
  // background-color: map.get(abstracts.$colors, "accent");
  cursor: pointer;
  transition: background-color 0.3s ease;

  h3 {
    margin: 0;
    color: map.get(abstracts.$colors, "text");
    text-shadow: 0px 0.07rem 0.1rem rgba(255, 255, 255, 0.2);
    padding-left: 0.4rem;
    padding-right: 0.4rem;

    @include abstracts.respond-to("xsmall") {
      font-size: 0.7rem;
      padding-top: 0.05rem;
    }
    @include abstracts.respond-to("small") {
      font-size: 0.8rem;
    }
    @include abstracts.respond-to("medium") {
      font-size: 0.9rem;
    }
  }

  &.action--go {
    color: map.get(abstracts.$colors, "secondary");
  }

  &.action--down {
    color: map.get(abstracts.$colors, "primary");
    background-color: transparent;
    border: 1px solid map.get(abstracts.$colors, "primary");
    border-radius: 0.5rem;
  }

  &.action--more {
    color: map.get(abstracts.$colors, "primary");
  }

  &.action--cs {
    background-color: map.get(abstracts.$colors, "accent");
    height: 24;
  }

  &:hover {
    background-color: darken(map.get(abstracts.$colors, "text"), 10%);
  }

  @include abstracts.respond-to("xsmall") {
    padding: 0.4rem;
  }
  @include abstracts.respond-to("small") {
    padding: 0.5rem;
  }
  @include abstracts.respond-to("medium") {
    padding: 0.55rem;
  }
  @include abstracts.respond-to("large") {
    padding: 0.6rem;
  }
}
