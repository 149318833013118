@use "../abstracts";
@use "sass:map";

#menu__items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  transform: translateY(-100%);
  opacity: 0;
  background-color: rgba(map.get(abstracts.$colors, "text"), 0.69);
  border-radius: 1rem;
  padding: 1rem;

  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  height: auto;
  width: auto;

  a {
    color: map.get(abstracts.$colors, "background");
    
    font-family: abstracts.$heading-font-stack;
    font-size: 0.7rem;
    
    padding: 1rem 1rem;

    transition: color 0.3s;

    &:hover {
      color: map.get(abstracts.$colors, "accent");
    }
  }

  &.open {
    transform: translateY(0);
    opacity: 1;
  }

  @include abstracts.respond-to("xsmall") {
    a {
      font-size: 1rem;
    }
  }

  @include abstracts.respond-to("small") {
    flex-direction: row;
    height: 100%;
    a {
      font-size: 1.3rem;
    }
  }

  @include abstracts.respond-to("medium") {
    a {
      font-size: 1.6rem;
    }
  }

  @include abstracts.respond-to("large") {
    a {
      font-size: 2rem;
    }
  }
}

#menu__container {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;

  margin: 1rem;
  padding: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  height: 4rem;

  z-index: 69;

  div {
    box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.42);
  }

  @include abstracts.respond-to("xsmall") {
    height: 4.5rem;
  }
  @include abstracts.respond-to("small") {
    height: 5rem;
  }
  @include abstracts.respond-to("medium") {
    height: 5.5rem;
  }
  @include abstracts.respond-to("large") {
    height: 6rem;
  }
}

#menu__toggle {
  height: 100%;
  backdrop-filter: blur(5rem);
  border: white 0.2rem solid;
  border-radius: 1rem;
  opacity: 0.69;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.93;
  }
}

#logo {
  height: 100%;
  width: auto;
  padding: 0.3rem;
}

.menu__item {
  font-weight: 700;
}