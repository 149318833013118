@import "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap";
html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.slide {
  z-index: 1;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.fp-overflow {
  display: contents;
}

.fp-arrow {
  transform: scale(.69) !important;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  color: #a56ec9;
  text-decoration: none;
}

a:hover, a:active, a:focus, a:focus-within {
  color: #f3edf8;
  text-decoration: underline;
}

body {
  color: #f3edf8;
}

h1, h2 {
  font-optical-sizing: auto;
  font-kerning: normal;
  text-align: center;
  text-shadow: 0 .07rem .1rem #0003;
  font-family: "Source Serif 4", Times New Roman, serif;
  font-style: normal;
}

h1:before, h1:after, h2:before, h2:after {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

h1:before, h2:before {
  content: "[ ";
  margin-right: .5rem;
}

h1:after, h2:after {
  content: " ]";
  margin-left: .5rem;
}

h1 {
  text-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  height: 100%;
  font-size: 1.6rem;
  font-weight: 900;
  display: flex;
}

h1 span {
  justify-content: center;
  align-items: last baseline;
  height: auto;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 450;
  display: inline-flex;
}

@media only screen and (width >= 384px) {
  h1 {
    font-size: 1.8rem;
  }

  h1 span {
    font-size: 1.2rem;
  }
}

@media only screen and (width >= 576px) {
  h1 {
    font-size: 2.1rem;
  }

  h1 span {
    font-size: 1.5rem;
  }
}

@media only screen and (width >= 768px) {
  h1 {
    font-size: 2.4rem;
  }

  h1 span {
    font-size: 1.8rem;
  }
}

@media only screen and (width >= 992px) {
  h1 {
    font-size: 2.6rem;
  }

  h1 span {
    font-size: 2rem;
  }
}

@media only screen and (width >= 1200px) {
  h1 {
    font-size: 2.8rem;
  }

  h1 span {
    font-size: 2.2rem;
  }
}

h2 {
  text-wrap: nowrap;
  font-size: .8rem;
  font-weight: 900;
}

h2 span {
  font-size: .5px;
  font-weight: 350;
}

@media only screen and (width >= 384px) {
  h2 {
    font-size: .9rem;
  }

  h2 span {
    font-size: .6rem;
  }
}

@media only screen and (width >= 576px) {
  h2 {
    font-size: 1.2rem;
  }

  h2 span {
    font-size: .9rem;
  }
}

@media only screen and (width >= 768px) {
  h2 {
    font-size: 1.4rem;
  }

  h2 span {
    font-size: 1.1rem;
  }
}

@media only screen and (width >= 992px) {
  h2 {
    font-size: 1.7rem;
  }

  h2 span {
    font-size: 1.4rem;
  }
}

h3 {
  text-align: center;
  text-shadow: 0 .07rem .1rem #f3edf81a;
  text-wrap: nowrap;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: .6rem;
  font-weight: 600;
}

@media only screen and (width >= 384px) {
  h3 {
    font-size: .7rem;
  }
}

@media only screen and (width >= 576px) {
  h3 {
    font-size: .8rem;
  }
}

@media only screen and (width >= 768px) {
  h3 {
    font-size: .9rem;
  }
}

@media only screen and (width >= 992px) {
  h3 {
    font-size: 1rem;
  }
}

#loader {
  opacity: 1;
  z-index: 1738;
  transition: opacity .6s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mask-image: radial-gradient(circle, #000 0%, #0000 69%);
}

#loader.fade-out {
  opacity: 0;
  pointer-events: none;
}

.slide__card {
  color: #f3edf8;
  z-index: 2;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: auto;
  height: auto;
  padding: .4rem;
  display: flex;
}

div.slide__container {
  color: #f3edf8;
  z-index: 2;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10vh 0;
  display: flex;
}

#s--home {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("nun_personal.63acab60.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--about {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("about.ab724d81.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--discography {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("discog.ddd5f710.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--music-videos {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("mvids.202a01bd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--performances {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("performances.28b58623.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--courtvision {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("cv.00e513c6.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--elsewhere-transmissions {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("elsewhere.6d59eb60.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--contact {
  background-blend-mode: multiply;
  opacity: .9;
  background-image: url("the_fisheye.3bd06bab.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#s--about {
  background-position: top;
}

#sr--1 div.slide {
  background-color: #a56ec9;
}

#sr--2 div.slide {
  background-color: #53936b;
}

#sr--3 div.slide {
  background-color: #d64c76;
}

#sr--4 div.slide {
  background-color: #f3edf8;
}

.slide__action {
  z-index: 4;
  cursor: pointer;
  background-color: #f3edf8;
  border: none;
  border-radius: .5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 36px;
  height: auto;
  min-height: 36px;
  padding: .3rem;
  transition: background-color .3s;
  display: flex;
}

.slide__action h3 {
  color: #f3edf8;
  text-shadow: 0 .07rem .1rem #fff3;
  margin: 0;
  padding-left: .4rem;
  padding-right: .4rem;
}

@media only screen and (width >= 384px) {
  .slide__action h3 {
    padding-top: .05rem;
    font-size: .7rem;
  }
}

@media only screen and (width >= 576px) {
  .slide__action h3 {
    font-size: .8rem;
  }
}

@media only screen and (width >= 768px) {
  .slide__action h3 {
    font-size: .9rem;
  }
}

.slide__action.action--go {
  color: #53936b;
}

.slide__action.action--down {
  color: #a56ec9;
  background-color: #0000;
  border: 1px solid #a56ec9;
  border-radius: .5rem;
}

.slide__action.action--more {
  color: #a56ec9;
}

.slide__action.action--cs {
  background-color: #d64c76;
  height: 24px;
}

.slide__action:hover {
  background-color: #dbc8ea;
}

@media only screen and (width >= 384px) {
  .slide__action {
    padding: .4rem;
  }
}

@media only screen and (width >= 576px) {
  .slide__action {
    padding: .5rem;
  }
}

@media only screen and (width >= 768px) {
  .slide__action {
    padding: .55rem;
  }
}

@media only screen and (width >= 992px) {
  .slide__action {
    padding: .6rem;
  }
}

#menu__items {
  opacity: 0;
  background-color: #f3edf8b0;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  height: auto;
  padding: 1rem;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out;
  display: flex;
  transform: translateY(-100%);
}

#menu__items a {
  color: #060307;
  padding: 1rem;
  font-family: "Source Serif 4", Times New Roman, serif;
  font-size: .7rem;
  transition: color .3s;
}

#menu__items a:hover {
  color: #d64c76;
}

#menu__items.open {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (width >= 384px) {
  #menu__items a {
    font-size: 1rem;
  }
}

@media only screen and (width >= 576px) {
  #menu__items {
    flex-direction: row;
    height: 100%;
  }

  #menu__items a {
    font-size: 1.3rem;
  }
}

@media only screen and (width >= 768px) {
  #menu__items a {
    font-size: 1.6rem;
  }
}

@media only screen and (width >= 992px) {
  #menu__items a {
    font-size: 2rem;
  }
}

#menu__container {
  z-index: 69;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  height: 4rem;
  margin: 1rem;
  padding: .5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#menu__container div {
  box-shadow: 0 0 1rem .5rem #0000006b;
}

@media only screen and (width >= 384px) {
  #menu__container {
    height: 4.5rem;
  }
}

@media only screen and (width >= 576px) {
  #menu__container {
    height: 5rem;
  }
}

@media only screen and (width >= 768px) {
  #menu__container {
    height: 5.5rem;
  }
}

@media only screen and (width >= 992px) {
  #menu__container {
    height: 6rem;
  }
}

#menu__toggle {
  backdrop-filter: blur(5rem);
  opacity: .69;
  border: .2rem solid #fff;
  border-radius: 1rem;
  height: 100%;
  transition: opacity .3s;
}

#menu__toggle:hover {
  opacity: .93;
}

#logo {
  width: auto;
  height: 100%;
  padding: .3rem;
}

.menu__item {
  font-weight: 700;
}
/*# sourceMappingURL=index.3eb3d086.css.map */
