#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.6s ease-out;
  mask-image: radial-gradient(circle, black 0%, transparent 69%);
  z-index: 1738;
}

#loader.fade-out {
  opacity: 0;
  pointer-events: none;
}
