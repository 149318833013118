// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@use "sass:map";
@use "./variables";

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Respond to a given breakpoint
/// @author hex benjamin
/// @param {String} $breakpoint - The breakpoint to respond to
@mixin respond-to($breakpoint) {
  $value: map.get(variables.$breakpoints, $breakpoint);
  @if $value != null {
    & {
      @media only screen and (min-width: $value) {
        @content;
      }
    }
  } @else {
    @error "No value could be found for `#{$breakpoint}` in `$breakpoints`.";
  }
}
