@use "../abstracts";
@use "sass:map";

.slide__card {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: auto;
  height: auto;
  color: map.get(abstracts.$colors, "text");
  padding: 0.4rem;
  z-index: 2;
}
