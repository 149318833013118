@use "../abstracts";
@use "sass:map";

.slide {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;

  // background
  background-position: center center;
  background-size: cover;
  z-index: 1;
}

.fp-overflow {
  display: contents;
}

.fp-arrow {
  transform: scale(0.69) !important;
}
